import { Outlet } from 'react-router'

const HomeLayout = () => {
  return (
    <div className="relative min-h-screen font-sans antialiased">
      <div className="relative">
        <Outlet />
      </div>
    </div>
  )
}

export default HomeLayout
