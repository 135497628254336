import { useState } from 'react'
import { cn } from '@/lib/utils'
import styles from './index.module.css'

import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { Label } from '@/components/ui/label'
import { Button } from '@/components/ui/button'
import LandingNavbar from '@/components/landing/landing-navbar'
import { isUserLogin } from '@/services/user'
import { toast } from 'sonner'
import { useNavigate } from 'react-router'

export interface PricingTierFrequency {
  id: string
  value: string
  label: string
  priceSuffix: string
}

export interface PricingTier {
  name: string
  id: string
  href: string
  discountPrice: string | Record<string, string>
  price: string | Record<string, string>
  description: string | React.ReactNode
  features: string[]
  featured?: boolean
  highlighted?: boolean
  cta: string
  soldOut?: boolean
}

export const frequencies: PricingTierFrequency[] = [
  { id: '1', value: '1', label: 'Monthly', priceSuffix: '/month' },
  { id: '2', value: '2', label: 'Annually', priceSuffix: '/year' },
]

export const tiers: PricingTier[] = [
  {
    name: 'Essential',
    id: '0',
    href: '/subscribe',
    price: { '1': '$9.9', '2': '$6.9' },
    discountPrice: { '1': '', '2': '' },
    description: `Discover the AI magic of Interview Copilot, perfect for preparing for 1 to 4 interviews each month.`,
    features: [
      `240 credits`,
      `Live Interview Copilot 60 credits / session (60mins)`,
    ],
    featured: false,
    highlighted: false,
    soldOut: false,
    cta: `Subscribe`,
  },
  {
    name: 'Pro',
    id: '1',
    href: '/subscribe',
    price: { '1': '$23.99', '2': '$49.99' },
    discountPrice: { '1': '', '2': '' },
    description: `Your 3-month Interview Copilot for steady success, tailored for 4 to 12 interviews in three months.`,
    features: [
      `720 credits`,
      `Live Interview Copilot 60 credits / session (60mins)`,
    ],
    featured: false,
    highlighted: false,
    soldOut: false,
    cta: `Subscribe`,
  },
  {
    name: 'Ultra',
    id: '2',
    href: '/subscribe',
    price: { '1': '$119.99', '2': '$179.88' },
    discountPrice: { '1': '', '2': '' },
    description: `Unlock your ultimate Interview Copilot for an entire year, perfect for 10 to 40 interviews.`,
    features: [
      `2880 credits`,
      `Live Interview Copilot 60 credits / session (60mins)`,
    ],
    featured: false,
    highlighted: true,
    soldOut: false,
    cta: `Subscribe`,
  },
]

const CheckIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={cn('h-6 w-6', className)}
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default function PricingPage() {
  const [frequency, setFrequency] = useState(frequencies[0])
  const bannerText = ''
  const navigate = useNavigate()
  const onSubscribe = (tier: PricingTier) => {
    if (isUserLogin()) {
      toast.warning('coming soon')
    } else {
      navigate('/login')
    }
  }
  return (
    <div>
      <section>
        <div className="relative">
          <LandingNavbar />
        </div>
      </section>

      <div
        className={cn('flex w-full flex-col items-center', styles.fancyOverlay)}
      >
        <div className="flex flex-col items-center w-full">
          <div className="flex flex-col items-center px-6 mx-auto max-w-7xl lg:px-8">
            {bannerText ? (
              <div className="flex justify-center w-full my-4 lg:w-auto">
                <p className="w-full px-4 py-3 text-xs text-black rounded-xl bg-slate-100 dark:bg-slate-300/30 dark:text-white/80">
                  {bannerText}
                </p>
              </div>
            ) : null}

            {frequencies.length > 1 ? (
              <div className="flex justify-center mt-16">
                <RadioGroup
                  defaultValue={frequency.value}
                  onValueChange={(value: string) => {
                    setFrequency(frequencies.find((f) => f.value === value)!)
                  }}
                  className="grid p-1 text-xs font-semibold leading-5 text-center bg-white rounded-full gap-x-1 ring-1 ring-inset ring-gray-200/30 dark:bg-black dark:ring-gray-800"
                  style={{
                    gridTemplateColumns: `repeat(${frequencies.length}, minmax(0, 1fr))`,
                  }}
                >
                  <Label className="sr-only">Payment frequency</Label>
                  {frequencies.map((option) => (
                    <Label
                      className={cn(
                        frequency.value === option.value
                          ? 'bg-blue-500/90 text-white dark:bg-blue-900/70 dark:text-white/70'
                          : 'bg-transparent text-gray-500 hover:bg-blue-500/10',
                        'cursor-pointer rounded-full px-2.5 py-2 transition-all',
                      )}
                      key={option.value}
                      htmlFor={option.value}
                    >
                      {option.label}

                      <RadioGroupItem
                        value={option.value}
                        id={option.value}
                        className="hidden"
                      />
                    </Label>
                  ))}
                </RadioGroup>
              </div>
            ) : (
              <div className="mt-12" aria-hidden="true"></div>
            )}

            <div
              className={cn(
                'isolate mx-auto mb-28 mt-4 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none',
                tiers.length === 2 ? 'lg:grid-cols-2' : '',
                tiers.length === 3 ? 'lg:grid-cols-3' : '',
              )}
            >
              {tiers.map((tier) => (
                <div
                  key={tier.id}
                  className={cn(
                    tier.featured
                      ? 'bg-blue-800 ring-blue-800 dark:bg-blue-400 dark:ring-blue-400' // 改用蓝色
                      : 'bg-white ring-gray-300/70 dark:bg-gray-900/80 dark:ring-gray-700',
                    'max-w-xs rounded-3xl p-8 ring-1 xl:p-10',
                    tier.highlighted ? styles.fancyGlassContrast : '',
                  )}
                >
                  <h3
                    id={tier.id}
                    className={cn(
                      tier.featured
                        ? 'text-white dark:text-blue-900' // 深色模式下的文字颜色
                        : 'text-black dark:text-white',
                      'text-2xl font-bold tracking-tight',
                    )}
                  >
                    {tier.name}
                  </h3>
                  <p
                    className={cn(
                      tier.featured
                        ? 'text-blue-100 dark:text-blue-700' // 调整描述文字颜色
                        : 'text-gray-600 dark:text-gray-400',
                      'mt-4 text-sm leading-6',
                    )}
                  >
                    {tier.description}
                  </p>
                  <p className="flex items-baseline mt-6 gap-x-1">
                    <span
                      className={cn(
                        tier.featured
                          ? 'text-white dark:text-black'
                          : 'text-black dark:text-white',
                        'text-4xl font-bold tracking-tight',
                        typeof tier.discountPrice === 'object' &&
                          tier.discountPrice &&
                          tier.discountPrice[frequency.value]
                          ? 'line-through'
                          : '',
                      )}
                    >
                      {typeof tier.price === 'string'
                        ? tier.price
                        : tier.price[frequency.value]}
                    </span>

                    <span
                      className={cn(
                        tier.featured
                          ? 'text-white dark:text-black'
                          : 'text-black dark:text-white',
                      )}
                    >
                      {typeof tier.discountPrice === 'string'
                        ? tier.discountPrice
                        : tier.discountPrice[frequency.value]}
                    </span>

                    {typeof tier.price !== 'string' ? (
                      <span
                        className={cn(
                          tier.featured
                            ? 'text-gray-300 dark:text-gray-500'
                            : 'text-gray-600 dark:text-gray-400',
                          'text-sm font-semibold leading-6',
                        )}
                      >
                        {frequency.priceSuffix}
                      </span>
                    ) : null}
                  </p>
                  <a
                    aria-describedby={tier.id}
                    className={cn(
                      'mt-6 flex shadow-sm',
                      tier.soldOut ? 'pointer-events-none' : '',
                    )}
                  >
                    <Button
                      size="lg"
                      disabled={tier.soldOut}
                      onClick={(e) => onSubscribe(tier)}
                      className={cn(
                        'w-full dark:text-white',
                        !tier.highlighted && !tier.featured
                          ? 'bg-slate-500 text-white hover:bg-slate-400 dark:bg-gray-600'
                          : 'bg-blue-500 text-white hover:bg-blue-400 dark:bg-slate-600 dark:hover:bg-slate-700',
                        tier.featured || tier.soldOut
                          ? 'bg-white hover:bg-gray-200 dark:bg-neutral-900 dark:hover:bg-black'
                          : 'transition-opacity hover:opacity-80',
                      )}
                      variant={tier.highlighted ? 'default' : 'outline'}
                    >
                      {tier.soldOut ? 'Sold out' : tier.cta}
                    </Button>
                  </a>

                  <ul
                    className={cn(
                      tier.featured
                        ? 'text-gray-300 dark:text-gray-500'
                        : 'text-gray-700 dark:text-gray-400',
                      'mt-8 space-y-3 text-sm leading-6 xl:mt-10',
                    )}
                  >
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className={cn(
                            tier.featured
                              ? 'text-white dark:text-blue-900' // 修改为蓝色主题
                              : tier.highlighted
                                ? 'text-blue-500' // 高亮状态使用蓝色
                                : 'text-gray-500', // 默认状态保持灰色
                            'h-6 w-5 flex-none',
                          )}
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
