import { Link } from 'react-router'

import { SignUpForm } from './sign-up-form'
import logo from '@/assets/logo.svg'

export default () => {
  return (
    <div className="flex flex-col w-full bg-white lg:w-1/2">
      <header className="lg:hidden w-full px-6 py-4 bg-[#F8FAFC]">
        <div className="max-w-screen-xl mx-auto">
          <Link to="/" className="flex items-center">
            <img src={logo} alt="DipOffer Logo" className="w-auto h-8" />
          </Link>
        </div>
      </header>
      <div className="flex items-center justify-center flex-1 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight">Sign Up</h2>
            <p className="mt-2 text-sm text-gray-600">Sign up your account</p>
          </div>
          <SignUpForm />
        </div>
      </div>
    </div>
  )
}
