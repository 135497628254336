import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { Toaster } from 'sonner'
import ErrorBoundary from '@/components/error-boundary'
import Routes from './router'

const queryClient = new QueryClient()

const App: React.FC = () => (
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <Routes />
      <Toaster position="top-center" richColors />
    </QueryClientProvider>
  </ErrorBoundary>
)
App.displayName = 'App'

export default App
