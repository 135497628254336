import React, { useState } from 'react' //
import { NavLink } from 'react-router'
import { Menu, X, LogOut, User } from 'lucide-react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'

import LandingButton from './landing-button'
import logo from '@/assets/logo.svg'
import { isUserLogin, useLogout } from '@/services/user'
import { cn } from '@/lib/utils'

const LandingNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const isLogin = isUserLogin()
  const { mutate: logout } = useLogout()
  const user = {
    name: 'test',
    email: 'test@example.com',
    avatarUrl: 'https://github.com/shadcn.png', // 替换为实际的头像URL
  }

  const navigationItems = [
    { name: 'Home', path: '/' },
    { name: 'Copilot', path: '/main' },
    { name: 'Pricing', path: '/pricing' },
    { name: 'About', path: '/' },
  ]

  const UserAvatar = () => (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className="focus:outline-none">
          <Avatar className="w-8 h-8 hover:opacity-80">
            <AvatarImage src={user.avatarUrl} alt={user.name} />
            <AvatarFallback>
              {user.name.slice(0, 2).toUpperCase()}
            </AvatarFallback>
          </Avatar>
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="w-56"
        sideOffset={8}
        alignOffset={0}
      >
        <DropdownMenuLabel>
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{user.name}</p>
            <p className="text-xs leading-none text-muted-foreground">
              {user.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <User className="w-4 h-4 mr-2" />
          <span>User Info</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => logout()}>
          <LogOut className="w-4 h-4 mr-2" />
          <span>Logout</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )

  return (
    <nav id="navbar" className="relative z-10 w-full text-neutral-800">
      <div className="flex items-center justify-between max-w-screen-xl px-8 py-4 mx-auto">
        <div className="flex items-center">
          <div>
            <img src={logo} className="w-24 xl:w-28" alt="Logo" />
          </div>

          <div className="items-center hidden ml-10 space-x-8 md:flex">
            {navigationItems.map((item, index) => (
              <NavLink
                key={index}
                to={item.path}
                className={({ isActive }) =>
                  cn(
                    'text-sm font-medium transition-colors hover:text-slate-900',
                    isActive ? 'text-slate-900' : 'text-slate-500',
                  )
                }
              >
                {item.name}
              </NavLink>
            ))}
          </div>
        </div>

        <div className="flex items-center">
          {/* 移动端菜单按钮 */}
          <button
            className="p-2 text-slate-500 hover:text-slate-900 md:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <X className="w-6 h-6" />
            ) : (
              <Menu className="w-6 h-6" />
            )}
          </button>

          {/* 桌面端按钮 */}
          <div className="items-center hidden space-x-3 md:flex">
            {isLogin ? (
              <div className="relative">
                <UserAvatar />
              </div>
            ) : (
              <>
                <LandingButton className="text-gradient border border-[#0c66ee] bg-inherit px-8 py-3 xl:px-10">
                  <NavLink to={'/login'}>Login</NavLink>
                </LandingButton>
                <LandingButton className="bg-gradient-to-r from-[#468ef9] to-[#0c66ee] px-8 py-3 text-white xl:px-10">
                  <NavLink to={'/sign-up'}>Sign Up</NavLink>
                </LandingButton>
              </>
            )}
          </div>
        </div>
      </div>

      {/* 移动端导航菜单 */}
      {isMenuOpen && (
        <div className="absolute left-0 w-full py-4 bg-white shadow-lg top-full md:hidden">
          <div className="flex flex-col px-8 space-y-4">
            {navigationItems.map((item) => (
              <NavLink
                key={item.path}
                to={item.path}
                onClick={() => setIsMenuOpen(false)}
                className={({ isActive }) =>
                  cn(
                    'py-2 text-sm font-medium transition-colors hover:text-slate-900',
                    isActive ? 'text-slate-900' : 'text-slate-500',
                  )
                }
              >
                {item.name}
              </NavLink>
            ))}
            {/* 移动端登录状态 */}
            {isLogin ? (
              <div
                onClick={logout}
                className="py-2 text-sm font-medium text-slate-500 hover:text-slate-900"
              >
                Logout
              </div>
            ) : (
              <>
                <NavLink
                  to="/login"
                  className="py-2 text-sm font-medium text-slate-500 hover:text-slate-900"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Login
                </NavLink>
                <NavLink
                  to="/sign-up"
                  className="py-2 text-sm font-medium text-slate-500 hover:text-slate-900"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Sign Up
                </NavLink>
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  )
}

export default LandingNavbar
