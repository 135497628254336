import * as React from 'react'
import {
  AudioWaveform,
  BookOpen,
  Bot,
  Command,
  Frame,
  GalleryVerticalEnd,
  Map,
  PieChart,
  Settings2,
  SquareTerminal,
} from 'lucide-react'

import { NavMain } from '@/components/nav-main'
import { NavProjects } from '@/components/nav-projects'
import { NavUser } from '@/components/nav-user'
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
  useSidebar,
} from '@/components/ui/sidebar'

import logoFull from '@/assets/logo.svg'
import logo from '@/assets/logo.png'

// This is sample data.
const data = {
  user: {
    name: 'test',
    email: 'test@dipoffer.com',
    avatar: '/avatars/shadcn.jpg',
  },
  navMain: [
    {
      title: 'Practice',
      url: '#',
      icon: SquareTerminal,
      isActive: true,
      items: [
        {
          title: 'History',
          url: '#',
        },
        {
          title: 'Starred',
          url: '#',
        },
        {
          title: 'Settings',
          url: '#',
        },
      ],
    },
    {
      title: 'Copilot',
      url: '#',
      icon: Bot,
      items: [
        {
          title: 'Interview',
          url: '#',
        },
      ],
    },
    {
      title: 'Interview Mock',
      url: '#',
      icon: BookOpen,
      items: [
        {
          title: 'Introduction',
          url: '#',
        },
        {
          title: 'Get Started',
          url: '#',
        },
        {
          title: 'Tutorials',
          url: '#',
        },
        {
          title: 'Changelog',
          url: '#',
        },
      ],
    },
    {
      title: 'Settings',
      url: '#',
      icon: Settings2,
      items: [],
    },
  ],
  projects: [
    {
      name: 'Design Engineering',
      url: '#',
      icon: Frame,
    },
    {
      name: 'Python Engineering',
      url: '#',
      icon: PieChart,
    },
    {
      name: 'Java Engineering',
      url: '#',
      icon: Map,
    },
  ],
}

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { open } = useSidebar()
  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <div className="flex items-center mt-4">
          {open ? (
            <>
              <img
                src={logoFull}
                alt="DipOffer Logo"
                className="w-auto h-8 ml-3"
              />
              <span className="ml-2 rounded-md bg-gradient-to-r from-indigo-500 to-purple-500 px-1.5 py-0.5 text-xs font-medium text-white">
                Pro
              </span>
            </>
          ) : (
            <img src={logo} alt="DipOffer Logo" className="w-auto h-6 ml-1" />
          )}
        </div>
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={data.navMain} />
        <NavProjects projects={data.projects} />
      </SidebarContent>
      <SidebarFooter>
        <NavUser user={data.user} />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  )
}
