import { BrowserRouter, Routes, Route } from 'react-router'

import MainLayout from '@/components/layout/main-layout'
import AuthLayout from '@/components/layout/auth-layout'

import Home from '@/pages/home/index'
import Main from '@/pages/main/index'
import NotFound from '@/pages/not-found/index'
import Login from '@/pages/auth/login/index'
import SignUp from '@/pages/auth/sign-up'
import HomeLayout from './components/layout/home-layout'
import Pricing from './pages/pricing'

export default () => (
  <BrowserRouter>
    <Routes>
      <Route element={<HomeLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="pricing" element={<Pricing />} />
      </Route>

      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="sign-up" element={<SignUp />} />
      </Route>

      <Route element={<MainLayout />}>
        <Route path="main" element={<Main />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
)
