import { Link, Outlet, Navigate } from 'react-router'

import landingHero from '@/assets/landing/landing_hero.png'
import logo from '@/assets/logo.svg'
import { isUserLogin } from '@/services/user'

const AuthLayout = () => {
  const isLogin = isUserLogin()
  if (isLogin) {
    return <Navigate to="/main" replace />
  }
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-1 lg:flex">
        <div className="lg:w-1/2 flex flex-col bg-[#F8FAFC]">
          <header className="w-screen px-6 py-4 lg:w-full">
            <div className="max-w-screen-xl mx-auto">
              <Link to="/" className="flex items-center">
                <img src={logo} alt="DipOffer Logo" className="w-auto h-8" />
              </Link>
            </div>
          </header>
          <div className="relative flex-1 hidden lg:block">
            <img
              src={landingHero}
              alt="Hero"
              className="object-contain w-full h-full p-8"
            />
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  )
}

export default AuthLayout
