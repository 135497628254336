import React from 'react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'

import landingFaq from '@/assets/landing/landing_faq.png'
import LandingSection from './landing-section'
import BlurFade from '../blur-fade'

const LandingFAQ = () => {
  const items = [
    {
      key: '1',
      label: (
        <div className="text-lg font-medium">
          What is the cancellation policy?
        </div>
      ),
      children: <div>What is the cancellation policy?</div>,
    },
    {
      key: '2',
      label: (
        <div className="text-lg font-medium">
          What is the policy for free trial?
        </div>
      ),
      children: <div>What is the policy for free trial?</div>,
    },
    {
      key: '3',
      label: (
        <div className="text-lg font-medium">
          Which domains are supported by DipOffer?
        </div>
      ),
      children: <div>Which domains are supported by DipOffer?</div>,
    },
    {
      key: '4',
      label: (
        <div className="text-lg font-medium">What payments are available?</div>
      ),
      children: <div>What payments are available?</div>,
    },
  ]
  return (
    <section className="w-full my-12">
      <LandingSection>
        <div className="col-span-12 lg:col-span-6">
          <div className="w-full">
            <BlurFade delay={0.25} duration={0.7} inView>
              <img src={landingFaq} className="w-full" alt="" />
            </BlurFade>
          </div>
        </div>
        <div className="col-span-12 px-4 mt-8 sm:px-6 lg:col-span-6">
          <BlurFade delay={0.25} duration={0.7} inView>
            <h2 className="mb-10 text-3xl font-semibold sm:mb-6 sm:text-4xl">
              FAQ
            </h2>
            <Accordion type="single" collapsible>
              {items.map((item, index) => (
                <AccordionItem key={index} value={item.key}>
                  <AccordionTrigger className="text-lg font-medium">
                    {item.children}
                  </AccordionTrigger>
                  <AccordionContent>{item.children}</AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </BlurFade>
        </div>
      </LandingSection>
    </section>
  )
}

export default LandingFAQ
