import { Link } from 'react-router'
import { useState } from 'react'

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useUsernameLogin } from '@/services/user'
import { toast } from 'sonner'

export function LoginForm() {
  const [user, setUser] = useState<API.UserLogin>({
    username: '',
    password: '',
  })
  const loginMutation = useUsernameLogin({
    onSuccess: () => {
      toast.success('login success')
      window.location.href = '/main'
    },
  })
  const onValueChange = ({ username, password }: any) => {
    username && (user.username = username)
    password && (user.password = password)
    setUser(user)
  }
  const handleSubmit = async () => {
    loginMutation.mutate(user)
  }
  return (
    <Card className="max-w-sm mx-auto">
      <CardHeader>
        <div />
      </CardHeader>
      <CardContent>
        <div className="grid gap-4">
          <div className="grid gap-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="m@example.com"
              required
              onChange={(e) => onValueChange({ username: e.target.value })}
            />
          </div>
          <div className="grid gap-2">
            <div className="flex items-center">
              <Label htmlFor="password">Password</Label>
              <Link to="#" className="inline-block ml-auto text-sm underline">
                Forgot your password?
              </Link>
            </div>
            <Input
              id="password"
              type="password"
              required
              onChange={(e) => onValueChange({ password: e.target.value })}
            />
          </div>
          <Button
            type="submit"
            className="w-full"
            onClick={(e) => handleSubmit()}
          >
            Login
          </Button>
          <Button variant="outline" className="w-full">
            Login with Google
          </Button>
        </div>
        <div className="mt-4 text-sm text-center">
          Don&apos;t have an account?{' '}
          <Link to="/sign-up" className="underline">
            Sign up
          </Link>
        </div>
      </CardContent>
    </Card>
  )
}
