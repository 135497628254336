import React from 'react'
import LandingButton from './landing-button'

import landingHero from '@/assets/landing/landing_hero.png'
import BlurFade from '../blur-fade'
import { isUserLogin } from '@/services/user'
import { useNavigate } from 'react-router-dom'

const LandingHero = () => {
  const navigate = useNavigate()
  const onGetStarted = () => {
    navigate(isUserLogin() ? '/main' : '/login')
  }
  return (
    <section className="w-full pb-12">
      <div className="relative grid max-w-screen-xl grid-cols-12 px-4 mx-auto overflow-hidden gap-x-6 sm:px-8">
        <div className="col-span-12 px-6 mt-12 lg:col-span-6 xl:mt-10">
          <div className="flex flex-col lg:h-full">
            <BlurFade delay={0.25} duration={0.7} inView>
              <div className="space-y-4 text-center sm:space-y-6 sm:text-left">
                <div className="xl:text4xl text-[2.5rem] font-bold capitalize leading-tight sm:pr-8 sm:text-3xl xl:pr-10">
                  Ace Your Next Interview{' '}
                  <div className="mt-3 text-[#226CFF]">
                    with Al and Community Support
                  </div>
                </div>
                <p className="paragraph hidden pt-6 text-[#8B97A9] sm:block">
                  DipOffer, the Uitimate interview Copilot
                </p>
              </div>
            </BlurFade>
            <BlurFade
              className="flex flex-col mt-4 mb-10 space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0 lg:mt-auto"
              delay={0.25}
              duration={1}
              inView
            >
              <LandingButton
                onClick={onGetStarted}
                className="max-w-full rounded-full border border-[#0c66ee] bg-gradient-to-r from-[#468ef9] to-[#0c66ee] px-8 py-4 text-center text-sm text-white transition duration-300 hover:shadow-md hover:shadow-[#0c66ee]/50"
              >
                Get Started
              </LandingButton>
              <LandingButton className="text-gradient flex max-w-full items-center justify-center rounded-full border border-[#0c66ee] bg-inherit px-6 py-4 text-center text-sm transition duration-300 hover:shadow-md hover:shadow-[#0c66ee]/50">
                <span>Download App</span>
              </LandingButton>
            </BlurFade>
          </div>
        </div>

        <div className="col-span-12 lg:col-span-6">
          <div className="w-full">
            <BlurFade delay={0.25} duration={0.7} inView>
              <img src={landingHero} />
            </BlurFade>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingHero
