import {
  get,
  getToken,
  removeToken,
  updateToken,
  useDelete,
  usePost,
} from '@/lib/request'

export async function queryCurrentUser(): Promise<API.UserInfo | undefined> {
  let response: API.RestResult<API.UserInfo> =
    await get<API.UserInfo>('/api/user/info')
  return response?.data
}

export function useLogout(option?: API.UseParam) {
  option = option || {}
  const origin = option.onSuccess
  option.onSuccess = (data) => {
    removeToken()
    origin && origin(data)
  }
  return useDelete('/api/user/logout', option)
}

export function useUsernameLogin(option?: API.UseParam) {
  option = option || {}
  const origin = option.onSuccess
  option.onSuccess = (token: API.UserToken) => {
    updateToken(token)
    origin && origin(token)
  }
  return usePost('/api/user/login', option)
}

export function isUserLogin() {
  const token = getToken()
  return !!token
}
