/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'sonner'
import axios from 'axios'
import queryString from 'query-string'
import type { AxiosRequestConfig } from 'axios'

const error = (message: string) => {
  toast.error(!!message ? message : 'request failed')
}

const success = (message: string) => {
  toast.success(message)
}

axios.interceptors.request.use(
  function (config: any) {
    config.headers['Authorization'] = localStorage.getItem('token') || ''
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (response) => {
    const config: AxiosRequestConfig & API.UseParam = response.config
    const resultResult = response.data
    if (!resultResult?.success && config.autoMessage !== false) {
      error(resultResult?.message || config?.errorMessage || 'request fail!')
    }
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)

function wrapParam(option?: API.UseParam): API.UseRealParam {
  if (!option) {
    return {
      onSuccess: () => {},
      onError: () => {},
      autoMessage: false,
    }
  }
  return {
    onSuccess: (data: API.RestResult<any>) => {
      if (data && data.success) {
        option.onSuccess && option.onSuccess(data?.data)
      } else {
        option.onFail && option.onFail()
      }
    },
    onError: () => option.onFail,
    autoMessage: option?.autoMessage === undefined ? true : option?.autoMessage,
    errorMessage: option?.errorMessage,
  }
}

function handleResult(response: any, option?: API.UseRealParam) {
  if (!option?.autoMessage) {
    return
  }

  let resultResult = response?.data
  console.log(resultResult)
  if (!resultResult?.success) {
    error(resultResult?.message || option?.errorMessage || 'request fail!')
  } else if (resultResult?.success) {
    success(resultResult.message || option?.errorMessage || 'request success!')
  }
}

export async function get<T>(url: string): Promise<API.RestResult<T>> {
  let response = await axios.get<API.RestResult<T>>(url)
  return response?.data
}

export async function request<T>(
  options: AxiosRequestConfig,
  customOption: API.UseParam = {},
): Promise<API.RestResult<T>> {
  let response = await axios({
    ...options,
    ...customOption,
  })
  return response?.data
}

export function useGet<T>(
  url: string,
  parameters: any,
  option?: API.UseParam,
): API.FetchResult<T> {
  let newOption = wrapParam(option)
  return useQuery({
    queryKey: [url, parameters],
    queryFn: async ({ queryKey }: any) => {
      let response = await axios.get<API.RestResult<T>>(
        url + (parameters ? '?' + queryString.stringify(queryKey[1]) : ''),
      )
      handleResult(response, newOption)
      return response?.data
    },
    retry: 2,
    refetchOnWindowFocus: false,
    ...newOption,
  })
}

export function useMutationRequest(
  url: string,
  method: string,
  option?: API.UseParam,
  axiosOptions: AxiosRequestConfig = {},
): API.MutationResult<any> {
  let newOption = wrapParam(option)
  return useMutation({
    mutationFn: async (req: any) => {
      let response = await axios({
        method,
        url,
        data: req,
        ...axiosOptions,
      }).catch(function () {
        error(option?.errorMessage || 'request fail!')
      })
      handleResult(response, newOption)
      return response?.data
    },
    retry: 0,
    ...newOption,
  })
}

export function usePost(
  url: string,
  option?: API.UseParam,
  axiosOptions?: AxiosRequestConfig,
): API.MutationResult<boolean> {
  return useMutationRequest(url, 'post', option, axiosOptions)
}

export function usePut(
  url: string,
  option?: API.UseParam,
  axiosOptions?: AxiosRequestConfig,
): API.MutationResult<boolean> {
  return useMutationRequest(url, 'put', option, axiosOptions)
}

export function useDelete(
  url: string,
  option?: API.UseParam,
): API.MutationResult<boolean> {
  return useMutationRequest(url, 'delete', option)
}

export function updateToken(token: API.UserToken) {
  localStorage.setItem('token', token.token)
  localStorage.setItem('refreshToken', token.refreshToken)
  localStorage.setItem('tokenExpireTime', token.expireTime + '')
}

export function removeToken() {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('tokenExpireTime')
}

export function getToken() {
  return localStorage.getItem('token')
}
